import styled from "styled-components";
import {media} from "../variables";

export const TopPhotoFrame = styled.div`
  background-image: url("/img/layout/background-main.svg"), linear-gradient( 112.1deg,  rgba(32,38,57,1) 11.4%, rgba(63,76,119,1) 70.2% );
  background-position: top left;
  background-size: 1920px;
  height: 350px;
`

export const TopPhotoText = styled.div`
  display: flex;
  height: 150px;
  line-height: 1;
  justify-content: space-around;
  flex-direction: column;
`

export const MainSection = styled.section`
  min-height: 60vh;
  margin-bottom: 60px;
`

export const TextElement = styled.div`
  font-size: 18px;
  margin-top: 40px;
  margin-left: 40px;
`

export const BoldText = styled.div`
  font-size: 24px;
  font-weight: bold;
`

export const DescriptionText = styled.div`
  
`

export const Container = styled.div`
    margin: 1rem 1rem;
    position: relative;

  @media (max-width: 1024px) {
    padding: 0 1rem;
  }
`;

export const ChapterHeading = styled.h2`
    font-size: 36px;
    font-weight: 700;
    text-align: left;

    @media (max-width: 768px) {
        font-size: 20px;
    }
`;

export const AbsolventCompanies = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;

    > img {
        margin: 2rem;
        height: 3.5rem;
    }

    @media (max-width: 1024px) {
        > img {
            margin: 2rem;
            height: 3rem;
        }
    }

    @media (max-width: 768px) {
        display: grid;
        grid-template-columns: 50% 50%;
        grid-template-rows: repeat(4, 1fr);
        grid-row-gap: 4rem;
        justify-items: center;
        margin: 5rem 0;

        > img {
            margin: 0.5rem;
            height: 2rem;
        }
    }

`;

export const ProFeatureDetails = styled.div`
    border-radius: 3px;
    background-color: #FFFFFF;
    box-shadow: 0 3px 10px 0 rgba(0,0,0,0.08);
    text-align: center;
    align-items: center;
    width: 95%;
    padding: 40px 100px;
    margin: 0 auto 32px auto;

    @media (max-width: 1024px) {
        width: 100%;
        padding: 20px;
        text-align: left;
    }
`;

export const ProFeatureName = styled.div`
  color: #00070B;
  font-size: 28px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 33px;
  text-align: center;
  margin-bottom: 30px;

    @media (max-width: 1024px) {
        font-size: 22px;
        line-height: 28px;
        text-align: left;
    }
`;

export const FeatureSection = styled.div`
  font-size: 24px;
  font-weight: 700;
  letter-spacing: 0;
  margin: 30px 0 10px 0;
  line-height: 25.4px;
  text-align: center;

    @media (max-width: 1024px) {
        display: block;
    }
`;

export const RefImage = styled.img`
  width: 300px;
  margin: 0 auto;
  text-align: center;
  display: block;

  @media (max-width: 1024px) {
    width: 160px;
  }
`;

export const LineSVG = styled.svg`
  @media (max-width: 1024px) {
      display: none;
  }
`;