import * as React from "react";
import Layout from '../../components/Layout'
import {MainText} from "../../components/MainComponents";
import {
    AbsolventCompanies,
    ChapterHeading,
    Container,
    FeatureSection,
    LineSVG,
    MainSection,
    ProFeatureDetails,
    ProFeatureName,
    RefImage,
    TopPhotoFrame
} from "../../components/Reference/layout";
import Carousel from "../../components/Carousel"
import Helmet from "react-helmet";

const data = [
    {
        image: "/img/pages/black-background-web.jpg",
            caption: `<div>
                  Absolvent workshopu Martin, Česko
                  <p>Workshop od TunaSec mi ukázal realně, co to znamená etický hacking. Přešli jsme si nástroje, které jsou k tomu využívány a to i s praktickými příklady.
                  Také mi ukázal mnoho stránek a jiných zdrojů, kde se můžu o problematice více naučit a i případně trénovat na příkladech.</p>
                </div>`
    },
    {
        image: "/img/pages/black-background-web.jpg",
        caption: `<div>
                  Ředitelka Gymnázia A. Merici, Trnava, Slovensko
                  <br/>
                  <p>Ján Masarik z TunaSec mal na našom gymnáziu viacero workshopov v rámci predmetu Informačná bezpečnosť, na ktorých prakticky ukázal
                   aké sú v online svete hrozby pre mladých ľudí a ako sa im brániť. Niektorých študentov dokonca nadchol pre kyberbezpečnosť tak, že sa jej začali aktívne venovať a pôsobil pre nich v úlohe mentora.</p>
                </div>`
    },
    {
        image: "/img/pages/black-background-web.jpg",
        caption: `<div>
                  Prof. Carla Tourita, ESCO, Portugal
                  <br/>
                  <p>This personal testimony was an encouragement for our young people, both those in the first year of the course, and for those in the final year. Both had the opportunity to speak to someone who started in the area with a path similar to theirs. We've also clarified several doubts and curiosities in the area of Web Security.</p>
                </div>`
    }
];

const captionStyle = {
    fontSize: '2em',
    fontWeight: 'bold',
    position: "relative",
    verticalAlign: "middle",
    height: "400px"
}

export default () => {
    const title = "Reference";

    return (
        <Layout>
            <Helmet titleTemplate={`Přečtěte si naše reference a hodnocení | TunaSec`}>
                {/* General tags */}
                <title>Přečtěte si naše reference a hodnocení | TunaSec</title>
                <meta name="robots" content="index, follow" />
                <meta name="keywords" content="klienti, zákazníci, reference, it security, it bezpečnost" />
                <meta property="og:title" content="Přečtěte si naše reference a hodnocení | TunaSec"/>
                <meta name="description" content="Mezi zákazníky máme školy a menší i středně velké společnosti, zranitelnosti jsme nahlásili i velkým technologickým gigantům." />
                <meta name="og:description" content="Mezi zákazníky máme školy a menší i středně velké společnosti, zranitelnosti jsme nahlásili i velkým technologickým gigantům." />
                <meta name="og:image" content="https://tunasec.com/img/og-images/reference.png" />

            </Helmet>
            <TopPhotoFrame className="full-width-image margin-top-0">
                    <MainText>{title}</MainText>
            </TopPhotoFrame>
            <MainSection className="section section--gradient container">
                <div style={{margin: "20px 0 80px", paddingLeft: "0 !important"}}>
                    <ChapterHeading style={{padding: "0 1rem"}}>Reference od studentů a škol</ChapterHeading>
                    <Carousel
                        data={data}
                        automatic={false}
                        time={0}
                        slideNumber={false}
                        thumbnails={false}
                        thumbnailWidth={0}
                        showNavBtn={true}
                        slideNumberStyle={{}}
                        width="100%"
                        height="400px"
                        captionStyle={captionStyle}
                        radius="10px"
                        captionPosition="center"
                        dots={true}
                        pauseIconColor="white"
                        pauseIconSize="10px"
                        slideBackgroundColor="none"
                        slideImageFit="cover"
                        style={{
                            textAlign: "center",
                            maxWidth: "100%",
                            margin: "30px auto",
                        }}
                    />
                </div>
                <Container style={{margin: "80px 0 120px"}}>
                    <ChapterHeading>Penetrační test</ChapterHeading>
                    <ProFeatureDetails style={{marginTop: "30px"}}>
                        <ProFeatureName><RefImage src={"/img/pages/cooperation-liferay.png"} alt={"Liferay logo"}/></ProFeatureName>
                        <p>
                            Mezi naše klienty patří i společnost Liferay, která poskytuje CRM řešení pro velké banky a společnosti jako Bosch nebo VMWare.
                        </p>
                        <FeatureSection>Předmět testování</FeatureSection>
                        <p>
                            Naše práce na projektu <b>Penetrační test pro software Liferay DXP 7.3</b> spočívala v analýze bezpečnostních zranitelností, které se nacházejí v aplikaci. Tato aplikace byla po penetračním testu uvolněna zákazníkům na aktualizaci jejich verze Liferay DXP.
                        </p>
                        <FeatureSection>Rozsah penetračního testu a výsledky</FeatureSection>
                        <p>
                            Rozsah práce byl se zákazníkem stanoven na <b>80 hodin</b>, během kterých jsme testovali software z pohledu bezpečnostních zranitelností. Výsledkem bylo dodání reportu, kde byly rozepsány nalezeny zranitelnosti a také doporučení, jak tyto problémy opravit.
                        </p>
                    </ProFeatureDetails>
                </Container>
                <Container style={{margin: "80px 0 120px"}}>
                    <ChapterHeading style={{marginBottom: "20px"}}>Zranitelnosti jsme úspěšně nahlásili</ChapterHeading>
                    <AbsolventCompanies>
                        <img alt="Tesla" src="/img/vulnerabilities/tesla.png"/>
                        <img alt="Starbucks" src="/img/vulnerabilities/starbucks.png"/>
                        <img alt="Google" src="/img/vulnerabilities/google.png"/>
                        <img alt="Spotify" src="/img/vulnerabilities/spotify.png"/>
                        <LineSVG preserveAspectRatio="none" viewBox="0 0 1 1"
                                 style={{width: "100%", marginTop: '0vw', height: '0vw', position: "relative"}}>
                        </LineSVG>
                        <img alt="GitLab" src="/img/vulnerabilities/gitlab.png"/>
                        <img alt="ESET" src="/img/vulnerabilities/eset.png"/>
                        <img alt="O2" src="/img/vulnerabilities/o2.png"/>
                        <img alt="T-Mobile" src="/img/vulnerabilities/tmobile.png"/>
                        <LineSVG preserveAspectRatio="none" viewBox="0 0 1 1"
                                 style={{width: "100%", marginTop: '0vw', height: '0vw', position: "relative"}}>
                        </LineSVG>
                        <img alt="Rohlik" src="/img/vulnerabilities/rohlik.png"/>
                        <img alt="DameJidlo" src="/img/vulnerabilities/damejidlo.png"/>
                        <img alt="Mall.cz" src="/img/vulnerabilities/mall.png"/>
                        <img alt="CSFD" src="/img/vulnerabilities/csfd.png"/>
                        <LineSVG preserveAspectRatio="none" viewBox="0 0 1 1"
                                 style={{width: "100%", marginTop: '0vw', height: '0vw', position: "relative"}}>
                        </LineSVG>
                        <img alt="Evernote" src="/img/vulnerabilities/evernote.png"/>
                        <img alt="Alza" src="/img/vulnerabilities/alza.png"/>
                        <img alt="DrMax" src="/img/vulnerabilities/drmax.png"/>
                        <img alt="CeskeDrahy" src="/img/vulnerabilities/cd.png"/>
                        <LineSVG preserveAspectRatio="none" viewBox="0 0 1 1"
                                 style={{width: "100%", marginTop: '0vw', height: '0vw', position: "relative"}}>
                        </LineSVG>
                        <img alt="Bugcrowd" src="/img/vulnerabilities/bugcrowd.png"/>
                        <img alt="TREZOR" src="/img/vulnerabilities/trezor.png"/>
                        <img alt="GoPay" src="/img/vulnerabilities/gopay.png"/>
                    </AbsolventCompanies>
                </Container>

            </MainSection>
        </Layout>
    );
}
